"use client";

import { useEffect, useState } from "react";
import PreferencesPopup from "../PreferencesPopup";
import { PortableText } from "@portabletext/react";

const CookieBanner = ({ cookieData }: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isCookiePolicyOpen, setIsCookiePolicyOpen] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    const isEssentialCookiesEnabled = localStorage.getItem("isEssentialCookiesEnabled");

    if (!cookiesAccepted || !isEssentialCookiesEnabled) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  const openPrivacyPolicy = () => setIsPrivacyOpen(true);
  const openCookiePolicy = () => setIsCookiePolicyOpen(true);
  const closePrivacyPolicy = () => setIsPrivacyOpen(false);
  const closeCookiePolicy = () => setIsCookiePolicyOpen(false);
  useEffect(() => {
    if (isPrivacyOpen || isCookiePolicyOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isPrivacyOpen, isCookiePolicyOpen]);
  const handleAccept = () => {
    localStorage.setItem("isEssentialCookiesEnabled", "true");
    localStorage.setItem("isPerformanceEnabled", "true");
    localStorage.setItem("isFunctionalityEnabled", "true");
    localStorage.setItem("isAdvertisingEnabled", "true");

    localStorage.setItem("cookiesAccepted", "true");
    setIsVisible(false);
  };

  const handleOpenPreferences = () => {
    setIsPreferencesOpen(true);
  };

  const handleClosePreferences = () => {
    setIsPreferencesOpen(false);

    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted) {
      setIsVisible(false);
    }
  };

  const portableTextComponents = {
    block: {
      normal: ({ children }: any) => <p className="mb-4 text-white">{children}</p>,
      h1: ({ children }: any) => <h1 className="text-2xl font-bold text-white mb-6">{children}</h1>,
      h2: ({ children }: any) => <h2 className="text-xl font-semibold text-white mt-6 mb-4">{children}</h2>,
      h3: ({ children }: any) => <h3 className="text-lg font-semibold text-white mt-4 mb-3">{children}</h3>,
    },
    list: {
      bullet: ({ children }: any) => <ul className="list-disc list-inside text-gray-300 mb-4">{children}</ul>,
      number: ({ children }: any) => <ol className="list-decimal list-inside text-gray-300 mb-4">{children}</ol>,
    },
    listItem: {
      bullet: ({ children }: any) => <li className="ml-4">{children}</li>,
      number: ({ children }: any) => <li className="ml-4">{children}</li>,
    },
    marks: {
      strong: ({ children }: any) => <strong className="text-white font-semibold">{children}</strong>,
      link: ({ children, value }: any) => (
        <a href={value.href} className="text-[#0997C5] underline hover:text-[#0997cfc7]">
          {children}
        </a>
      ),
      privacyPolicyLink: ({ children }: any) => (
        <button className="text-[#0997C5]  underline hover:text-[#0997cfc7]" onClick={openPrivacyPolicy}>
          {children}
        </button>
      ),
      cookiesPolicyLink: ({ children }: any) => (
        <button className="text-[#0997C5]  underline hover:text-[#0997cfc7]" onClick={openCookiePolicy}>
          {children}
        </button>
      ),
    },
  };

  const portableTextComponents2 = {
    block: {
      normal: ({ children }: any) => <p className=" text-white">{children}</p>,
    },
    marks: {
      privacyPolicyLink: ({ children }: any) => (
        <button className="text-[#0997C5]  underline hover:text-[#0997cfc7]" onClick={openPrivacyPolicy}>
          {children}
        </button>
      ),
      cookiesPolicyLink: ({ children }: any) => (
        <button className="text-[#0997C5]  underline hover:text-[#0997cfc7]" onClick={openCookiePolicy}>
          {children}
        </button>
      ),
    },
  };

  if (!isVisible) return null;

  return (
    <>
      <div className="fixed bottom-0 left-0 right-0 bg-[#333b49] py-6 px-4 md:px-12 lg:px-28 shadow-md flex flex-col md:flex-row items-start md:items-center justify-between z-50 text-sm space-y-4 md:space-y-0">
        <div className="w-full md:w-7/12 xl:w-8/12">
          {cookieData && cookieData.cookiesMainContent && cookieData.cookiesMainContent.content ? (
            <PortableText value={cookieData.cookiesMainContent.content} components={portableTextComponents2} />
          ) : (
            <p className="text-white">
              🍪 We use cookies to improve your browsing experience.{" "}
              <button className="text-blue-400 underline" onClick={openCookiePolicy}>
                Learn more
              </button>
            </p>
          )}
        </div>

        <div className="w-full md:w-5/12 xl:w-4/12 flex flex-col sm:flex-row gap-2 justify-end">
          <button className="w-full sm:w-auto border border-white text-white px-6 py-3 rounded-3xl hover:text-[#0997C5] hover:border-[#0997C5] active:bg-[#ffffff28]" onClick={handleOpenPreferences}>
            {cookieData?.cookiesMainContent?.managePreferencebutton || "Manage Preferences"}
          </button>
          <button className="w-full sm:w-auto bg-[#0997C5] hover:bg-[#0997cfc7] active:bg-[#0996c586] text-white px-6 py-3 rounded-3xl" onClick={handleAccept}>
            {cookieData?.cookiesMainContent?.acceptbutton || "Accept"}
          </button>
        </div>
      </div>

      {isPreferencesOpen && <PreferencesPopup managePreferenceContent={cookieData?.managePreferenceContent} privacyPolicy={cookieData?.privacyPolicy} cookiesPolicy={cookieData?.cookiesPolicy} isOpen={isPreferencesOpen} onClose={handleClosePreferences} />}

      {isPrivacyOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-[#33404E] bg-opacity-75">
          <div className="bg-[#333b49] text-white rounded-lg w-11/12 md:w-2/3 lg:w-1/2 shadow-lg">
            <div className="bg-[#0997C5] px-4 py-3 rounded-t-lg flex justify-between items-center">
              <h2 className="text-xl font-normal">Privacy Policy</h2>
            </div>
            <div className="p-4 max-h-[60vh] overflow-y-auto my-2 mx-2">{cookieData?.privacyPolicy ? <PortableText value={cookieData.privacyPolicy.content} components={portableTextComponents} /> : <p className="text-gray-300 mb-4">This Privacy Policy explains how we handle your personal information. It details what data we collect, how we use it, and your rights regarding your data.</p>}</div>
            <div className="flex justify-center py-6 pr-4">
              <button onClick={closePrivacyPolicy} className="bg-[#0997C5] hover:bg-[#0997cfc7] active:bg-[#0996c586] text-white px-10 py-3 rounded-3xl">
                Back
              </button>
            </div>
          </div>
        </div>
      )}

      {isCookiePolicyOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-[#33404E] bg-opacity-75">
          <div className="bg-[#333b49] text-white rounded-lg w-11/12 md:w-2/3 lg:w-1/2 shadow-lg">
            <div className="bg-[#0997C5] px-4 py-3 rounded-t-lg flex justify-between items-center">
              <h2 className="text-xl font-normal">Cookie Policy</h2>
            </div>
            <div className="p-4 max-h-[60vh] overflow-y-auto my-2 mx-2">{cookieData?.cookiesPolicy ? <PortableText value={cookieData.cookiesPolicy.content} components={portableTextComponents} /> : <p className="text-gray-300 mb-4">This Cookie Policy explains what cookies are, how we use them, and your rights to control their usage.</p>}</div>
            <div className="flex justify-center py-6 pr-4">
              <button onClick={closeCookiePolicy} className="bg-[#0997C5] hover:bg-[#0997cfc7] active:bg-[#0996c586] text-white px-10 py-3 rounded-3xl">
                Back
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
