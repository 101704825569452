"use client";

import { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";

interface PreferencesPopupProps {
  isOpen: boolean;
  onClose: () => void;
  privacyPolicy: any;
  cookiesPolicy: any;
  managePreferenceContent: any;
}

const PreferencesPopup: React.FC<PreferencesPopupProps> = ({ isOpen, onClose, privacyPolicy, cookiesPolicy, managePreferenceContent }) => {
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isCookiePolicyOpen, setIsCookiePolicyOpen] = useState(false);

  const [preferences, setPreferences] = useState({
    performance: true,
    functionality: true,
    advertising: true,
  });

  useEffect(() => {
    const isPerformanceEnabled = localStorage.getItem("isPerformanceEnabled");
    const isFunctionalityEnabled = localStorage.getItem("isFunctionalityEnabled");
    const isAdvertisingEnabled = localStorage.getItem("isAdvertisingEnabled");

    setPreferences({
      performance: isPerformanceEnabled !== "false",
      functionality: isFunctionalityEnabled !== "false",
      advertising: isAdvertisingEnabled !== "false",
    });
  }, []);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setPreferences((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSavePreferences = () => {
    localStorage.setItem("isEssentialCookiesEnabled", "true");
    localStorage.setItem("isPerformanceEnabled", preferences.performance.toString());
    localStorage.setItem("isFunctionalityEnabled", preferences.functionality.toString());
    localStorage.setItem("isAdvertisingEnabled", preferences.advertising.toString());

    localStorage.setItem("cookiesAccepted", "true");

    onClose();
  };

  const openPrivacyPolicy = () => setIsPrivacyOpen(true);
  const openCookiePolicy = () => setIsCookiePolicyOpen(true);

  const closePrivacyPolicy = () => setIsPrivacyOpen(false);
  const closeCookiePolicy = () => setIsCookiePolicyOpen(false);
  const portableTextComponents = {
    block: {
      normal: ({ children }: any) => <p className="mb-4 text-white">{children}</p>,
      h1: ({ children }: any) => <h1 className="text-2xl font-bold text-white mb-6">{children}</h1>,
      h2: ({ children }: any) => <h2 className="text-xl font-semibold text-white mt-6 mb-4">{children}</h2>,
      h3: ({ children }: any) => <h3 className="text-lg font-semibold text-white mt-4 mb-3">{children}</h3>,
    },
    list: {
      bullet: ({ children }: any) => <ul className="list-disc list-inside text-gray-300 mb-4">{children}</ul>,
      number: ({ children }: any) => <ol className="list-decimal list-inside text-gray-300 mb-4">{children}</ol>,
    },
    listItem: {
      bullet: ({ children }: any) => <li className="ml-4">{children}</li>,
      number: ({ children }: any) => <li className="ml-4">{children}</li>,
    },
    marks: {
      strong: ({ children }: any) => <strong className="text-white font-semibold">{children}</strong>,
      link: ({ children, value }: any) => (
        <a href={value.href} className="text-[#0997C5] underline hover:text-[#0997cfc7]">
          {children}
        </a>
      ),
      privacyPolicyLink: ({ children }: any) => (
        <button className="text-[#0997C5] underline hover:text-[#0997cfc7]" onClick={openPrivacyPolicy}>
          {children}
        </button>
      ),
      cookiesPolicyLink: ({ children }: any) => (
        <button className="text-[#0997C5] underline hover:text-[#0997cfc7]" onClick={openCookiePolicy}>
          {children}
        </button>
      ),
    },
  };
  const portableTextComponents2 = {
    block: {
      normal: ({ children }: any) => <p className="mb-4 text-white">{children}</p>,
    },
    marks: {
      privacyPolicyLink: ({ children }: any) => (
        <button className="text-[#0997C5] underline hover:text-[#0997cfc7]" onClick={openPrivacyPolicy}>
          {children}
        </button>
      ),
      cookiesPolicyLink: ({ children }: any) => (
        <button className="text-[#0997C5] underline hover:text-[#0997cfc7]" onClick={openCookiePolicy}>
          {children}
        </button>
      ),
    },
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-[#33404E] bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-[#33404E] text-white rounded-lg w-11/12 md:w-2/3 lg:w-1/2 shadow-lg">
          <div className="bg-[#0997C5] px-4 py-3 rounded-t-lg flex justify-between items-center">
            <h2 className="text-xl font-normal">Preferences</h2>
            <button className="curesor-pointer" onClick={() => onClose()}>
              ✖
            </button>
          </div>
          <div className="p-4 max-h-[60vh] overflow-y-auto my-2 mx-2">
            {managePreferenceContent && managePreferenceContent.content && <PortableText value={managePreferenceContent.content} components={portableTextComponents2} />}

            <div className="mt-8 mb-6 space-y-8">
              <div>
                <label className="flex items-start gap-2">
                  <input type="checkbox" className="form-checkbox text-white mt-2 cursor-not-allowed" checked disabled />
                  <div>
                    <span className="text-white font-semibold opacity-50">{managePreferenceContent.cookieCategories[0].name}</span>
                    <p className="text-white text-xs opacity-50">{managePreferenceContent.cookieCategories[0].description}</p>
                  </div>
                </label>
              </div>

              <div>
                <label className="flex items-start gap-2">
                  <input type="checkbox" name="performance" className="form-checkbox text-[#0997C5] mt-2" checked={preferences.performance} onChange={handleCheckboxChange} />
                  <div>
                    <span className="text-white font-semibold">{managePreferenceContent.cookieCategories[1].name}</span>
                    <p className="text-white text-xs">{managePreferenceContent.cookieCategories[1].description}</p>
                  </div>
                </label>
              </div>

              <div>
                <label className="flex items-start gap-2">
                  <input type="checkbox" name="functionality" className="form-checkbox text-[#0997C5] mt-2" checked={preferences.functionality} onChange={handleCheckboxChange} />
                  <div>
                    <span className="text-white font-semibold">{managePreferenceContent.cookieCategories[2].name}</span>
                    <p className="text-white text-xs">{managePreferenceContent.cookieCategories[2].description}</p>
                  </div>
                </label>
              </div>

              <div>
                <label className="flex items-start gap-2">
                  <input type="checkbox" name="advertising" className="form-checkbox text-[#0997C5] mt-2" checked={preferences.advertising} onChange={handleCheckboxChange} />
                  <div>
                    <span className="text-white font-semibold">{managePreferenceContent.cookieCategories[3].name}</span>
                    <p className="text-white text-xs">{managePreferenceContent.cookieCategories[3].description}</p>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 py-6 pr-10">
            <button onClick={handleSavePreferences} className="bg-[#0997C5] hover:bg-[#0997cfc7] active:bg-[#0996c586] text-white px-6 py-3 rounded-3xl">
              {managePreferenceContent?.confirmbutton || "Save Preferences"}
            </button>
          </div>
        </div>
      </div>

      {/* Privacy Policy Popup */}
      {isPrivacyOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-[#33404E] bg-opacity-75">
          <div className="bg-[#333b49] text-white rounded-lg w-11/12 md:w-2/3 lg:w-1/2 shadow-lg">
            <div className="bg-[#0997C5] px-4 py-3 rounded-t-lg flex justify-between items-center">
              <h2 className="text-xl font-normal">Privacy Policy</h2>
            </div>
            <div className="p-4 max-h-[60vh] overflow-y-auto my-2 mx-2">{privacyPolicy ? <PortableText value={privacyPolicy.content} components={portableTextComponents} /> : <p className="text-gray-300 mb-4">This Privacy Policy explains how we handle your personal information. It details what data we collect, how we use it, and your rights regarding your data.</p>}</div>
            <div className="flex justify-center py-6 pr-4">
              <button onClick={closePrivacyPolicy} className="bg-[#0997C5] hover:bg-[#0997cfc7] active:bg-[#0996c586] text-white px-10 py-3 rounded-3xl">
                Back
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Cookies Policy Popup */}
      {isCookiePolicyOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-[#33404E] bg-opacity-75">
          <div className="bg-[#333b49] text-white rounded-lg w-11/12 md:w-2/3 lg:w-1/2 shadow-lg">
            <div className="bg-[#0997C5] px-4 py-3 rounded-t-lg flex justify-between items-center">
              <h2 className="text-xl font-normal">Cookie Policy</h2>
            </div>
            <div className="p-4 max-h-[60vh] overflow-y-auto my-2 mx-2">{cookiesPolicy ? <PortableText value={cookiesPolicy.content} components={portableTextComponents} /> : <p className="text-gray-300 mb-4">This Cookie Policy explains what cookies are, how we use them, and your rights to control their usage.</p>}</div>
            <div className="flex justify-center py-6 pr-4">
              <button onClick={closeCookiePolicy} className="bg-[#0997C5] hover:bg-[#0997cfc7] active:bg-[#0996c586] text-white px-10 py-3 rounded-3xl">
                Back
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreferencesPopup;
